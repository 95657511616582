<div class="col-md-12" data-ng-if="showPersonalPage">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="personal.personalZipFormGroup" (ngSubmit)="onSubmit()" autocomplete="on">
        <div class="info" style="padding-top: 0;">
          <div *ngIf="email.invalid && (submitTry || email.dirty || email.touched)"
               class="alert alert-danger">
            <div *ngIf="email.errors.required">
              Die Email-Adresse wird für die Angebotserstellung benötigt.
            </div>
          </div>
          <label>
            <strong>Email-Adresse:</strong>
            <input id="email" formControlName="email" class="form-control" type="email" size="26" maxlength="255" value="" autocomplete="email"><br>
          </label>
        </div><br><br>
        <div class="info">
          Für die Berechnung der Frachtkosten geben Sie bitte Ihre 5-stellige Postleitzahl an.
          <br><br>
          <div *ngIf="zip.invalid && (submitTry || zip.dirty || zip.touched)"
               class="alert alert-danger">
            <div *ngIf="zip.errors.required">
              PLZ wird für die Berechnung der Frachtkosten benötigt.
            </div>
            <div *ngIf="zip.errors.minlength">
              PLZ muss 5 Zeichen lang sein.
            </div>
          </div>
          <label>
          <strong>PLZ: &nbsp;</strong>
            <input id="zip" formControlName="zip" class="form-control" type="tel" size="5" maxlength="5" value="" autocomplete="shipping postal-code"><br>
          </label>
          <label>
            <strong>Land: </strong>
            <select name="landauswahl" formControlName="country" class="form-control" >
              <option *ngFor="let item of personal.countryPossible" [ngValue]="item">{{item}}</option>
            </select>
          </label>
        </div>
        <br style="clear: left;"><br>

        <div class="info">
          Unsere Carports werden generell als Bausatz so vorgefertigt, dass der <strong>Aufbau </strong>durch einen erfahrenen
          DIY Heimwerker selbst durchgeführt werden kann. Sollten Sie jedoch den Aufbau durch uns oder eine kooperierende
          Fachfirma wünschen, kalkulieren wir Ihnen gerne unverbindlich die Kosten für einen fach- und termingerechten Aufbau.
        </div>

        <div class="row info" style="padding-top: 20px;">
          <div *ngIf="aufbau.invalid && (submitTry || aufbau.dirty || aufbau.touched)" class="col-md-12 alert alert-danger">
            <div *ngIf="aufbau.errors.required">
              Aufbau-Variante wird benötigt.
            </div>
          </div>
          <div class="col-md-6">
            <label for="selector-1">
              <input id="selector-1" class="selector" type="radio" name="aufbau" formControlName="aufbau" value="Selbstaufbau">
              <strong>Selbstaufbau </strong>durch Bauträger
            </label>
          </div>
          <div class="col-md-6" data-ng-if="personal.country_liefer === 'Deutschland'">
            <label for="selector-2">
              <input id="selector-2" class="selector" type="radio" name="aufbau" formControlName="aufbau" value="Aufbauservice">
              <strong>Aufbauservice </strong>durch Fachfirma
            </label>
          </div>
        </div>
      </form>
    </div>
    <!--<button type="button" class="submit float-right" (click)="onDownloadPDF()">Angebot als PDF erhalten</button>
    <button type="button" class="submit float-right" (click)="onSubmit()">Weitere Wünsche angeben</button>-->
    <!--<input class="submit float-right" type="image" src="/assets/images/calculator/button.next.png" title="weiter zum nächsten Auswahlschritt" alt="Submit" (click)="onSubmit()">-->
  </div>
</div>
