<div class="row">
  <div class="card mt-2 px-0" *ngFor="let value of allItems | keyvalue; let i = index" [hidden]="needTwoColumns ? i%2===1 : false" (click)="chooseValue(value.value)" [ngClass]="isBoxActive(value.value)">
    <div class="card-header">
      <h2 class="card-title">{{value.value.title}}</h2>
    </div>
    <div class="card-body p-4">
      <div class="row">
        <div [ngClass]="columnWidthImage">
          <img class="image choiceImage" [src]="'/assets/images/calculator/icons/' + generateImage(value.value.image)" [title]="value.value.title" [alt]="value.value.title">
        </div>
        <div [ngClass]="columnWidthDescription" bind-innerHTML="value.value.description"></div>
        <div [ngClass]="columnWidthDescription" *ngIf="value.value.hasOwnProperty('anzahl')">
          <input [(ngModel)]="value.value.anzahl" type="tel" placeholder=0 pattern="/^[0-9]*$/" size="4">
          <span>&nbsp;</span>
          <label>Stück</label>
          <span *ngIf="isBoxActive(value.value) === 'active'" class="active">&#x2714;</span>
          <span *ngIf="isBoxActive(value.value) === 'error'" class="error">&#x2718;</span><br>
          <span *ngIf="isBoxActive(value.value) === 'error'" class="error">Bitte geben Sie eine numerische Anzahl ein!</span>
        </div>
        <div [ngClass]="columnWidthDescription" *ngIf="value.value.hasOwnProperty('color')">
          <select [(ngModel)]="value.value.color">
            <option value="ohne">keine Farbgebung</option>
            <option value="weiß">Weiß</option>
            <option value="anthrazit">Anthrazit</option>
            <option value="pinie">Pinie</option>
          </select>
        </div>
      </div>
      <br style="clear: left;">
    </div>
  </div>
</div>
