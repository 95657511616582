import type {Deserializable} from './deserializable.model';
import type {PriceArr} from './priceArr';

export class Price implements Deserializable {
  gesamt: number;
  rabatt: number;
  preis: number;
  loggingID: string;
  icon: string;
  abmessungen: {
    gesamtBreite: number,
    gesamtLaenge: number,
    durchfahrtsBreite: number
  };

  constructor() {
    this.gesamt = 0;
    this.rabatt = 0;
    this.preis = 0;
    this.loggingID = '';
    this.icon = '';
    this.abmessungen = {
      durchfahrtsBreite: 0,
      gesamtBreite: 0,
      gesamtLaenge: 0
    };
  }

  deserialize(input: PriceArr): this {
    return Object.assign(this, input);
  }
}
