import { Injectable } from '@angular/core';
import {Personal} from './Models/personal.model';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  private personalValue = new Personal();

  getPersonal(): Personal {
    return this.personalValue;
  }
}
