import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';
import * as chalk from 'chalk';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  log(msg: any, text?: string): void {
    if (!environment.production) {
      const userAgent = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
        return;
      }
      const errorStack = (new Error().stack).split('@');
      if (errorStack.length > 1) {
        const logLineDetail = (errorStack[1]).trim();
        const callingFunction = logLineDetail.split('\n')[1];
        console.log(chalk.red((new Date()).toISOString()) + ' (' + chalk.green(callingFunction) + ') ' + (text ? (text + ' ') : '') + JSON.stringify(msg));
      } else {
        console.log(errorStack);
      }
    }// */
  }
}
