import type { OnInit} from '@angular/core';
import {Component, Input, Output, EventEmitter, Inject} from '@angular/core';
import {ApiService} from '../api.service';
import {LogService} from '../log.service';
import type {IAbfragenChosen} from '../Models/abfragen';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PriceService} from "../price.service";
import {Price} from "../Models/price.model";

@Component({
  selector: 'app-price',
  styleUrls: ['./price.component.css'],
  templateUrl: './price.component.html'
})
export class PriceComponent implements OnInit {
  @Input() allChosenValues: IAbfragenChosen;
  @Output() saveLoggingID = new EventEmitter<string>();

  prices: Price;
  constructor(@Inject(ApiService) private api: ApiService,
              @Inject(LogService) private logger: LogService,
              @Inject(PriceService) private priceService: PriceService,
              @Inject(GoogleAnalyticsService) private $gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.api.getPrice(this.allChosenValues.getValues())
      .subscribe( data => {
        if (data.status !== 200) {
          this.logger.log('Price error');
          this.logger.log(data.status);
          this.logger.log(data.body);
        }
        this.logger.log(data.body);
        this.prices = new Price();
        this.prices.deserialize(data.body);
        this.priceService.setPrice(this.prices);
        this.saveLoggingID.emit(this.prices.loggingID);
        this.$gaService.event("priceCalculated", "kalkulator", this.prices.icon, this.prices.preis);
      });
  }
}
