<div class="col-md-12 priceview" *ngIf="prices">
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <img class="priceImage" [src]="'/assets/images/calculator/3d/' + prices.icon + '.gif'" title="Ihr individuelles Carport!" alt="Ihr individuelles Carport!" *ngIf="prices.hasOwnProperty('icon')">
        <br>
        - nur Holzgrundkonstruktion abgebildet, nicht maßstabsgetreu -
      </div>
      <br>
      <div class="row">
        <div class="col text-right list-1">
          <p>
            <strong>Carportpreis:</strong><br>
            <strong>Online-Rabatt:</strong><br>
            <strong>Ihr Preis:</strong>
          </p>
        </div>

        <div class="col text-left list-2">
          <p>
            <strong>{{prices.gesamt | number: '1.2-2'}} €</strong><br>
            <strong>-{{prices.rabatt | number: '1.2-2'}} €</strong><br>
            <strong>{{prices.preis | number: '1.2-2'}} €</strong>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <p>
            Gesamtbreite:<br>
            Gesamtlänge:<br>
            Durchfahrtsbreite:<br>
            Durchfahrtshöhe:
          </p>
        </div>

        <div class="col text-left" *ngIf="prices.hasOwnProperty('abmessungen')">
          <p>
            {{prices.abmessungen.gesamtBreite}} m<br>
            {{prices.abmessungen.gesamtLaenge}} m<br>
            {{prices.abmessungen.durchfahrtsBreite}} m<br>
            2,10 m
          </p>
        </div>
      </div>
      <div class="info">
        <strong>Hinweis:</strong><br>
        In den nachfolgenden Schritten haben Sie die Gelegenheit <strong>individuelle Wünsche</strong> und Ideen anzugeben und Ihre <strong>unverbindliche Anfrage</strong> an uns zu übermitteln. Die Angebotspreise für Montage und Lieferung erhalten Sie sofort und unverbindlich nach dem Absenden per Mail.
      </div>
      <br style="clear: left;">
      <small>Alle Angebote sind unverbindlich und freibleibend. Für Fehler oder Irrtümer wird keine Haftung übernommen.</small>
      <input type="hidden" name="submit" value="1">
    </div>
  </div>
</div>
