import type { OnInit} from '@angular/core';
import {Component, EventEmitter, Inject, Output} from '@angular/core';
import type {Personal} from '../Models/personal.model';
import type {UntypedFormControl} from '@angular/forms';
import {LogService} from '../log.service';
import {PersonalService} from '../personal.service';
import {PriceService} from "../price.service";
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-personal-plz',
  styleUrls: ['./personal-plz.component.css'],
  templateUrl: './personal-plz.component.html'
})
export class PersonalPlzComponent implements OnInit {
  @Output() requestNextPage = new EventEmitter();
  @Output() requestDownloadPDF = new EventEmitter();

  personal: Personal;
  submitTry;

  constructor(@Inject(LogService) private logger: LogService,
              @Inject(PersonalService) private personalService: PersonalService,
              @Inject(PriceService) private priceService: PriceService,
              @Inject(GoogleAnalyticsService) private $gaService: GoogleAnalyticsService) {
    this.submitTry = false;
  }

  ngOnInit(): void {
    this.personal = this.personalService.getPersonal();
    this.personal.createPersonalZipFormGroup();
    this.$gaService.event("personalDataZipInit", "kalkulator", "personalZipInit", this.priceService.getPrice().preis);
  }

  onSubmit(): void {
    this.logger.log('onSubmit');
    this.submitTry = true;
    if (this.personal.personalZipFormGroup.valid) {
      this.personal.writeZipData();
      this.$gaService.event("personalDataZipSubmit", "kalkulator", "personalZipSubmit", this.priceService.getPrice().preis);
      this.requestNextPage.emit();
    }
  }

  /**
   * @description downloads PDF-File
   */
  onDownloadPDF(): void {
    this.logger.log('onDownloadPDF');
    this.submitTry = true;
    if (this.personal.personalZipFormGroup.valid) {
      this.personal.writeZipData();
      this.requestDownloadPDF.emit();
    }
  }

  get email(): UntypedFormControl {
    return this.personal.personalZipFormGroup.get('email') as UntypedFormControl;
  }

  get zip(): UntypedFormControl {
    return this.personal.personalZipFormGroup.get('zip') as UntypedFormControl;
  }

  get country(): UntypedFormControl {
    return this.personal.personalZipFormGroup.get('country') as UntypedFormControl;
  }

  get aufbau(): UntypedFormControl {
    return this.personal.personalZipFormGroup.get('aufbau') as UntypedFormControl;
  }

  get anregung(): UntypedFormControl {
    return this.personal.personalZipFormGroup.get('anregung') as UntypedFormControl;
  }

}
