import type {
  OnInit,
  OnChanges,
  SimpleChanges} from '@angular/core';
import {
  Component,
  EventEmitter, Inject,
  Input,
  Output
} from '@angular/core';
import {LogService} from '../log.service';
import type {IAbfrageItem} from '../Models/abfragen';

@Component({
  selector: 'app-auswahl',
  styleUrls: ['./auswahl.component.css'],
  templateUrl: './auswahl.component.html'
})
export class AuswahlComponent implements OnChanges {
  @Output() selectRequest = new EventEmitter<IAbfrageItem>();
  @Input() allItems: IAbfrageItem[];
  @Input() preSelectedValue: IAbfrageItem;

  columnWidth: string;
  columnWidthImage: string;
  columnWidthDescription: string;
  needTwoColumns: boolean;
  selectedValue: IAbfrageItem;

  constructor(@Inject(LogService) private logger: LogService) {
    // this.selectedValue = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'allItems') {
        if (Object.keys(this.allItems).length > 2) {
          this.columnWidth = 'col-md-6';
          this.columnWidthDescription = 'col-md-12 description';
          this.columnWidthImage = 'col-md-12';
        }
        else {
          this.columnWidth = 'col-md-12';
          this.columnWidthDescription = 'col-md-6 description';
          this.columnWidthImage = 'col-md-6';
        }
      }
      else if (propName === 'preSelectedValue') {
        if (this.preSelectedValue === undefined) {
          this.selectedValue = undefined;
          continue;
        }
        this.selectedValue = this.preSelectedValue;
        Object.keys(this.allItems).forEach(itemKey => {
          if (Object.prototype.hasOwnProperty.call(this.allItems[itemKey], 'anzahl')
            && this.allItems[itemKey].id === this.preSelectedValue.id) {
            this.allItems[itemKey].anzahl = this.preSelectedValue.anzahl;
          }
          if (Object.prototype.hasOwnProperty.call(this.allItems[itemKey], 'color')
            && this.allItems[itemKey].id === this.preSelectedValue.id) {
            this.allItems[itemKey].color = this.preSelectedValue.color;
          }
        })
      }
    }
  }

  /**
   * @description saves current selection
   */
  chooseValue(value: IAbfrageItem): void {
    this.selectedValue = value;
    this.selectRequest.emit(value);
  }

  /**
   * @description tests if Box is active
   */
  isBoxActive(value: IAbfrageItem): string {
    let classValue = 'col-12';
    if (Object.keys(this.allItems).length > 2) {
      classValue = 'col-6';
    }
    if (this.selectedValue
      && Object.prototype.hasOwnProperty.call(this.selectedValue, 'id')
      && value.id === this.selectedValue.id) {
      classValue += ' active';
    }
    this.logger.log(value.toString());
    try {
      this.logger.log(this.selectedValue.toString());
    } catch (e) {
      this.logger.log(e);
    }
    if (Object.prototype.hasOwnProperty.call(value, 'anzahl')) {
      if (isNaN(value.anzahl) || (value.anzahl < 0 || value.anzahl > 10)) {
        classValue = 'error';
      }
    }
    return classValue;
  }

  /**
   * @returns name of Image
   */
  generateImage(imageName: string): string {
    return imageName;
  }
}
