<div class="col-md-12">
  <form autocomplete="on" name="kontakt" [formGroup]="personal.personalFormGroup" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="card-body">
        <div class="info" style="padding-top: 0;">
          Um Ihre <strong>unverbindliche</strong> Anfrage abzuschließen und Ihnen ein PDF zu übermitteln benötigen wir nur noch
          Ihre EMail-Adresse. Die restlichen Angaben zu Ihrer Person dienen der vorbereitung Ihres individuellen Angebots.
          Diese personenbezogenen Daten werden <strong>ausschließlich zur Erstellung Ihres Angebots verwandt.</strong>
        </div>
        <div *ngIf="personalFormError()" class="info" style="color:red">
          Bitte füllen Sie die erforderlichen Felder aus!
        </div>
        <div class="personal">
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              Anrede:
            </p>
            <p class="personal-2 col-sm-8">
              <select name="gender" formControlName="gender">
                <option value="Frau">Frau</option>
                <option value="Herr">Herr</option>
                <option value="Fam">Fam</option>
              </select>
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              Vorname, Name:
            </p>
            <p class="personal-2 col-sm-8">
              <input type="text" name="fname" autocomplete="given-name" size="10" maxlength="50" value="" formControlName="firstname" placeholder="Vorname">
              <input type="text" name="name" autocomplete="family-name" size="11" maxlength="50" value="" formControlName="lastname" placeholder="Nachname">
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              Straße, Nr:
            </p>
            <p class="personal-2 col-sm-8">
              <input type="text" name="ship-address" autocomplete="shipping street-address" size="26" maxlength="100" value="" formControlName="address" placeholder="Straße, Nr.">
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              Land, PLZ, Ort:
            </p>
            <p class="personal-2 col-sm-8">
              <select name="landauswahl" formControlName="country" data-ng-options="option for option in countryPossibleShort" class="d-none d-sm-block">
                <option *ngFor="let item of personal.countryPossible" [ngValue]="item">{{item}}</option>
              </select>
              <input type="tel" name="ship-zip" autocomplete="shipping postal-code" size="5" maxlength="5" value="" formControlName="zip" placeholder="PLZ">
              <input type="text" name="ship-city" autocomplete="shipping address-level2" size="16" maxlength="50" value="" formControlName="city" placeholder="Ort">
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block" *ngIf="personal.personalFormGroup.get('favoriteContact').value === 'Telefon'">
              <strong>Telefonnummer:</strong>
            </p>
            <p class="personal-1 col-sm-4 d-none d-sm-block" *ngIf="personal.personalFormGroup.get('favoriteContact').value === 'Email'">
              Telefonnummer:
            </p>
            <p class="personal-2 col-sm-8">
              <input type="tel" name="phone" autocomplete="tel" size="26" maxlength="255" value="" formControlName="phone" placeholder="Telefonnummer">
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              Telefaxnummer:
            </p>
            <p class="personal-2 col-sm-8">
              <input type="tel" name="fax" autocomplete="fax" size="26" maxlength="255" value="" formControlName="fax" placeholder="Faxnummer">
            </p>
          </div>
          <div class="row">
            <p class="personal-1 col-sm-4 d-none d-sm-block">
              <strong>Email-Adresse:</strong>
            </p>
            <p class="personal-2 col-sm-8">
              <input type="email" name="email" autocomplete="email" size="26" maxlength="255" value="" formControlName="email" placeholder="E-Mail*">
            </p>
          </div>
          <br style="clear: left;"><br>
          <p class="personal-1 col-xs-12 col-md-6 personal-contact">
            <input type="radio" id="contact-1" name="favoriteContact" value="Telefon" formControlName="favoriteContact">
            <label class="personal" for="contact-1">bitte kontaktieren Sie mich telefonisch</label>
          </p>
          <p class="personal-1 col-xs-12 col-md-6 personal-contact">
            <input type="radio" id="contact-2" name="favoriteContact" value="Email" formControlName="favoriteContact">
            <label class="personal" for="contact-2">bitte kontaktieren Sie mich per Email</label>
          </p>
          <br style="clear: left;">
        </div>
        <div class="info">
          <strong>Platz für Wünsche und eigene Ideen:</strong><br>
          <textarea class="text" formControlName="anregung" placeholder="Beispieltext:&#10;- Maß 5,30 x 8,00m &#10; - Dachneigung ca. 15° &#10; - Trapezform (vorne 5,3m; hinten 6,0m) &#10; - Farbe: rot deckend &#10; - gedämmter Geräteraum"></textarea>
        </div>
        <div class="info">
          <input type="hidden" name="check" value="1">
          Nach Angabe Ihrer Kontaktdaten senden wir Ihnen per Email Ihr persönliches Angebot
          zum  kalkulierten Carport im <strong>PDF-Format</strong> zu.
        </div>

      </div>
    </div>
  </form>
  <!--<button type="button" class="submit float-right" (click)="onSubmit()">Unverbindliche Anfrage senden</button>-->
  <!--<input class="submit pull-right" type="image" src="/assets/images/calculator/button.next.png" title="weiter zum nächsten Auswahlschritt" alt="Submit" (click)="onSubmit()">-->
</div>
