import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import type { HttpResponse} from '@angular/common/http';
import type {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import type {IAbfragen} from './Models/abfragen';
import type {PersonalArr} from './Models/personal';

const baseUrl = environment.baseURL;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getAbfragen(): Observable<HttpResponse<any>> {
    return this.http.get('/data/abfragen.json', {observe: 'response'});
  }

  getPrice(chosenValues: IAbfragen): Observable<HttpResponse<any>> {
    return this.http.post(baseUrl + 'price-calculation', chosenValues, {observe: 'response'});
  }

  savePDF(chosenValues: IAbfragen, personal: PersonalArr, loggingID: string): Observable<Blob> {
    return this.http.post(
      baseUrl + 'downloadPDF',
      {carport: chosenValues, loggingID, personal},
      {responseType: 'blob'});
  }

  sendMail(chosenValues: IAbfragen, personal: PersonalArr, loggingID: string): Observable<HttpResponse<any>> {
    return this.http.post(
      baseUrl + 'sendmail',
      {carport: chosenValues, loggingID, personal},
      {observe: 'response'});
  }
}
