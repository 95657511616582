import type {IAbfrageItem, IAbfragen, IAbfragenChosen} from './abfragen';
import type {Deserializable} from './deserializable.model';
import type {LogService} from '../log.service';


export class AbfrageItem extends Array<IAbfrageItem> implements Deserializable {
  public id: string | object;
  public title: string | object;
  public image: string | object;
  public description: string | object;
  public color: string;
  public anzahl: number;
  public requirement: {
    not: string | string[]
  }
  public subAbfrage: AbfrageItem | AbfrageItem[];
  public sub: boolean;

  deserialize(input: IAbfrageItem): this {
    Object.assign(this, input);
    if (input.subAbfrage instanceof Array) {
      this.subAbfrage = [];
      for(const typeVal of input["subAbfrage"]) {
        this.subAbfrage.push(new AbfrageItem().deserialize(typeVal));
      }
    }
    return this;
  }

  toString(): string {
    return "id: " + JSON.stringify(this.id) + " title: " + JSON.stringify(this.title);
  }

  testAbfrageItemNeeded(sub, currentChosenValueIds: string[], currentChosenValueIdsGrob: string[], logger: LogService) {
    logger.log(this.toObject())
    let currentTestAbfrage: IAbfrageItem = new AbfrageItem();
    currentTestAbfrage.sub = sub;
    for(const testItemKey in this) {
      const res = this.testItemKey(testItemKey, currentTestAbfrage, currentChosenValueIds, currentChosenValueIdsGrob, logger)
      if (res === undefined) return undefined;
      currentTestAbfrage = res;
    }
    return currentTestAbfrage;
  }

  private testItemKey(key: string, currentTestAbfrage: IAbfrageItem, currentChosenValueIds: string[], currentChosenValueIdsGrob: string[], logger: LogService) {
    logger.log(key, "key")
    const testItem = this[key];
    if (key === "subAbfrage") {
      currentTestAbfrage.subAbfrage = this.subAbfrage;
      return currentTestAbfrage;
    }
    if (key === 'requirement' && this.requirement.not) {
      // Requirement might be an array and needs to be iterated
      if (typeof this.requirement.not === "object" ) {
        for (const requirement of this.requirement.not) {
          if (currentChosenValueIds.includes(requirement)) {
            return undefined;
          }
        }
      }
      if (typeof this.requirement.not === 'string' && currentChosenValueIds.includes(this.requirement.not)) {
        return undefined;
      }
      return currentTestAbfrage;
    }
    let disableAbfrage = true;
    const primitiveCondition = this.getSpecifiedConditionalValue(testItem, currentChosenValueIdsGrob, logger);
    if (primitiveCondition !== undefined) {
      currentTestAbfrage[key] = primitiveCondition;
      disableAbfrage = false;
    }
    logger.log(disableAbfrage, "disableAbfrage");
    if(! disableAbfrage) {
      return currentTestAbfrage;
    }
    return undefined;
  }

  private getSpecifiedConditionalValue(testItem, currentChosenValueIdsGrob: string[], logger: LogService): number | string {
    logger.log(testItem, "TestItem in getSpecifiedConditionalValue");
    logger.log(currentChosenValueIdsGrob, "currentChosenValueIDs in getSpecifiedConditionalValue");
    switch(typeof testItem) {
      case 'string':
      case 'number':
        return testItem;
      case 'object':
        for (const conditionKey of Object.keys(testItem)) {
          const condition = testItem[conditionKey]
          if (currentChosenValueIdsGrob.includes(conditionKey)) {
            if (typeof condition === 'string' || typeof condition === 'number') {
              return condition;
            }
            else {
              return this.getSpecifiedConditionalValue(condition, currentChosenValueIdsGrob, logger);
            }
          }
        }
        return undefined;
      default:
        logger.log('Abfrage Typ ungültig');
        logger.log(typeof testItem);
        logger.log(testItem);
        return undefined
    }
  }

  toObject() {
    const retVal = {
      anzahl: this.anzahl ? this.anzahl : undefined,
      color: this.color ? this.color : undefined,
      id: this.id,
      subAbfrage: undefined,
      title: this.title
    };
    if (this.subAbfrage && this.subAbfrage instanceof AbfrageItem) {
      retVal.subAbfrage = this.subAbfrage.toObject();
    }
    return retVal;
  }
}

export class Abfragen implements IAbfragen, Deserializable {
  public Bauart: IAbfrageItem[];
  public Typ: IAbfrageItem[];
  public Holzart: IAbfrageItem[];
  public Farbgebung: IAbfrageItem[];
  public Dach: IAbfrageItem[];
  public Stellplatz: IAbfrageItem[];
  public Geräteraum: IAbfrageItem[];
  public Eindeckung: IAbfrageItem[];
  public Abbund: IAbfrageItem[];
  public Dachrinne: IAbfrageItem[];
  public Pfostenträger: IAbfrageItem[];
  public Holzbogen: IAbfrageItem[];
  public Solar: IAbfrageItem[];
  public Sichtschutz: IAbfrageItem[];

  deserialize(input: any): this {
    Object.keys(input).forEach(type => {
      this[type] = [];
      for(const typeVal of input[type]) {
        this[type].push(new AbfrageItem().deserialize(typeVal));
      }
    })
    return this
  }
}

export class AbfragenChosen implements IAbfragenChosen {
  public Bauart: AbfrageItem;
  public Typ: AbfrageItem;
  public Holzart: AbfrageItem;
  public Farbgebung: AbfrageItem;
  public Dach: AbfrageItem;
  public Stellplatz: AbfrageItem;
  public Geräteraum: AbfrageItem;
  public Eindeckung: AbfrageItem;
  public Abbund: AbfrageItem;
  public Dachrinne: AbfrageItem;
  public Pfostenträger: AbfrageItem;
  public Holzbogen: AbfrageItem;
  public Solar: AbfrageItem;
  public Sichtschutz: AbfrageItem;

  getValues() {
    return {
      Abbund: this.Abbund.toObject(),
      Bauart: this.Bauart.toObject(),
      Dach: this.Dach.toObject(),
      Dachrinne: this.Dachrinne.toObject(),
      Eindeckung: this.Eindeckung.toObject(),
      Farbgebung: this.Farbgebung.toObject(),
      Geräteraum: this.Geräteraum.toObject(),
      Holzart: this.Holzart.toObject(),
      Holzbogen: this.Holzbogen.toObject(),
      Pfostenträger: this.Pfostenträger.toObject(),
      Sichtschutz: this.Sichtschutz.toObject(),
      Solar: this.Solar.toObject(),
      Stellplatz: this.Stellplatz.toObject(),
      Typ: this.Typ.toObject()
    }
  }

}


