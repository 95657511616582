import type { OnInit} from '@angular/core';
import {Component, EventEmitter, Inject, Output} from '@angular/core';
import type {Personal} from '../Models/personal.model';
import {LogService} from '../log.service';
import type {UntypedFormControl} from '@angular/forms';
import {ApiService} from '../api.service';
import {PersonalService} from '../personal.service';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PriceService} from "../price.service";
import {Price} from "../Models/price.model";

@Component({
  selector: 'app-personal',
  styleUrls: ['./personal.component.css'],
  templateUrl: './personal.component.html'
})
export class PersonalComponent implements OnInit {
  @Output() requestSendMail = new EventEmitter();
  personal: Personal;
  price: Price;
  submitTry;

  constructor(@Inject(ApiService) private api: ApiService,
              @Inject(LogService) private logger: LogService,
              @Inject(PersonalService) private personalService: PersonalService,
              @Inject(PriceService) private priceService: PriceService,
              @Inject(GoogleAnalyticsService) private $gaService: GoogleAnalyticsService) {
    this.submitTry = false;
  }

  ngOnInit(): void {
    this.personal = this.personalService.getPersonal();
    this.price = this.priceService.getPrice();
    this.personal.createPersonalFormGroup();
    this.$gaService.event("personalDataInit", "kalkulator", "personalInit", this.price.preis);
  }

  onSubmit(): void {
    this.submitTry = true;
    if (this.personal.personalFormGroup.valid) {
      this.personal.writeData();
      this.$gaService.event("personalDataSubmit", "kalkulator", "personalSubmit", this.price.preis);
      // this.$gaService.event("emailSent", "kalkulator", this.price.icon, this.price.preis);
      this.requestSendMail.emit();
    }
  }

  personalFormError(): boolean {
    if (!this.submitTry) {
      return false;
    }
    return this.personal.personalFormGroup.invalid;
  }

  get gender(): UntypedFormControl {
    return this.personal.personalFormGroup.get('gender') as UntypedFormControl;
  }

  get firstname(): UntypedFormControl {
    return this.personal.personalFormGroup.get('firstname') as UntypedFormControl;
  }

  get lastname(): UntypedFormControl {
    return this.personal.personalFormGroup.get('lastname') as UntypedFormControl;
  }

  get address(): UntypedFormControl {
    return this.personal.personalFormGroup.get('address') as UntypedFormControl;
  }

  get country(): UntypedFormControl {
    return this.personal.personalFormGroup.get('country') as UntypedFormControl;
  }

  get zip(): UntypedFormControl {
    return this.personal.personalFormGroup.get('zip') as UntypedFormControl;
  }

  get city(): UntypedFormControl {
    return this.personal.personalFormGroup.get('city') as UntypedFormControl;
  }

  get phone(): UntypedFormControl {
    return this.personal.personalFormGroup.get('phone') as UntypedFormControl;
  }

  get fax(): UntypedFormControl {
    return this.personal.personalFormGroup.get('fax') as UntypedFormControl;
  }

  get email(): UntypedFormControl {
    return this.personal.personalFormGroup.get('email') as UntypedFormControl;
  }

  get favoriteContact(): UntypedFormControl {
    return this.personal.personalFormGroup.get('favoriteContact') as UntypedFormControl;
  }
}
