import {Injectable} from '@angular/core';
import {Price} from './Models/price.model';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  private priceValue = new Price();

  getPrice(): Price {
    return this.priceValue;
  }

  setPrice(price: Price): void {
    this.priceValue = price;
  }
}
