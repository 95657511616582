import type {Deserializable} from './deserializable.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import type {PersonalArr} from './personal';

export class Personal implements Deserializable {
  public gender: string;
  public firstname: string;
  public lastname: string;
  public address: string;
  public city: string;
  public zip: string;
  public phone: string;
  public fax: string;
  public email: string;
  public favoriteContact: string;
  public anregung: string;
  public aufbau: string;
  public zipLiefer: string;
  public countryLiefer: string;
  public countryPossible = ['Belgien', 'Bulgarien', 'Dänemark', 'Deutschland', 'Estland', 'Finnland', 'Frankreich', 'Griechenland',
    'Irland', 'Italien', 'Kroatien', 'Lettland', 'Litauen', 'Luxemburg', 'Niederlande', 'Österreich', 'Polen',
    'Portugal', 'Rumänien', 'Schweden', 'Schweiz', 'Slowakei', 'Slowenien', 'Spanien', 'Tschechische Republik',
    'Ungarn', 'Vereinigtes Königreich'];
  public personalFormGroup: UntypedFormGroup;
  public personalZipFormGroup: UntypedFormGroup;

  constructor() {
    this.gender = '';
    this.firstname = '';
    this.lastname = '';
    this.address = '';
    this.city = '';
    this.phone = '';
    this.fax = '';
    this.email = '';
    this.favoriteContact = 'Email';
    this.anregung = '';
    this.aufbau = 'Selbstaufbau';
    this.zip = '';
    this.zipLiefer = '';
    this.countryLiefer = 'Deutschland';
  }

  deserialize(input: Personal): this {
    return Object.assign(this, input);
  }

  createPersonalFormGroup(): void {
    this.personalFormGroup = new UntypedFormGroup({
      address: new UntypedFormControl(this.address),
      city: new UntypedFormControl(this.city),
      country: new UntypedFormControl(this.countryLiefer),
      favoriteContact: new UntypedFormControl(this.favoriteContact),
      fax: new UntypedFormControl(this.fax),
      firstname: new UntypedFormControl(this.firstname),
      gender: new UntypedFormControl(this.gender),
      lastname: new UntypedFormControl(this.lastname),
      phone: new UntypedFormControl(this.phone),
      zip: new UntypedFormControl(this.zipLiefer, [
        Validators.minLength(5)
      ]),
      email: new UntypedFormControl(this.email, [
        Validators.required
      ]),
      anregung: new UntypedFormControl(this.anregung),
    });
  }

  createPersonalZipFormGroup(): void {
    this.personalZipFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl(this.email, [
        Validators.required
      ]),
      aufbau: new UntypedFormControl(this.aufbau, [
        Validators.required
      ]),
      country: new UntypedFormControl(this.countryLiefer),
      zip: new UntypedFormControl(this.zipLiefer, [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
  }

  getArray(): PersonalArr {
    const personalArr: PersonalArr = {
      address: this.address,
      anregung: this.anregung,
      aufbau: this.aufbau,
      city: this.city,
      country: '',
      country_liefer: this.countryLiefer,
      email: this.email,
      favoriteContact: this.favoriteContact,
      fax: this.fax,
      firstname: this.firstname,
      gender: this.gender,
      lastname: this.lastname,
      phone: this.phone,
      zip: this.zipLiefer,
      zip_liefer: this.zipLiefer
    };
    personalArr.country = this.mapCountry();
    if (this.mapCountry() !== 'D' ) {
      personalArr.zip = this.mapCountry() + '-' + this.zipLiefer;
    }
    return personalArr;
  }

  writeZipData(): void {
    this.email = this.personalZipFormGroup.get('email').value;
    this.zipLiefer = this.personalZipFormGroup.get('zip').value;
    this.countryLiefer = this.personalZipFormGroup.get('country').value;
    this.aufbau = this.personalZipFormGroup.get('aufbau').value;
    // this.anregung = this.personalZipFormGroup.get('anregung').value;
  }

  writeData(): void {
    this.gender = this.personalFormGroup.get('gender').value;
    this.firstname = this.personalFormGroup.get('firstname').value;
    this.lastname = this.personalFormGroup.get('lastname').value;
    this.address = this.personalFormGroup.get('address').value;
    this.countryLiefer = this.personalFormGroup.get('country').value;
    this.zip = this.personalFormGroup.get('zip').value;
    this.city = this.personalFormGroup.get('city').value;
    this.phone = this.personalFormGroup.get('phone').value;
    this.fax = this.personalFormGroup.get('fax').value;
    this.favoriteContact = this.personalFormGroup.get('favoriteContact').value;
    this.email = this.personalFormGroup.get('email').value;
    this.anregung = this.personalFormGroup.get('anregung').value;
  }

  mapCountry(): string {
    switch (this.countryLiefer) {
      case 'Belgien':
        return 'B';
      case 'Bulgarien':
        return 'BG';
      case 'Dänemark':
        return 'DK';
      case 'Deutschland':
        return 'D';
      case 'Estland':
        return 'EST';
      case 'Finnland':
        return 'FIN';
      case 'Frankreich':
        return 'F';
      case 'Griechenland':
        return 'GR';
      case 'Irland':
        return 'IRL';
      case 'Italien':
        return 'I';
      case 'Kroatien':
        return 'HR';
      case 'Lettland':
        return 'LV';
      case 'Litauen':
        return 'LT';
      case 'Luxemburg':
        return 'L';
      case 'Niederlande':
        return 'NL';
      case 'Österreich':
        return 'A';
      case 'Polen':
        return 'PL';
      case 'Portugal':
        return 'P';
      case 'Rumänien':
        return 'RO';
      case 'Schweden':
        return 'S';
      case 'Schweiz':
        return 'CH';
      case 'Slowakei':
        return 'SK';
      case 'Slowenien':
        return 'SLO';
      case 'Spanien':
        return 'E';
      case 'Tschechische Republik':
        return 'CZ';
      case 'Ungarn':
        return 'H';
      case 'Vereinigtes Königreich':
        return 'GB';
      default:
        return '';
    }
  }
}
