import type { OnInit} from '@angular/core';
import {Component, Inject} from '@angular/core';
import {PersonalService} from '../personal.service';
import type {Personal} from '../Models/personal.model';

@Component({
  selector: 'app-mail-send',
  styleUrls: ['./mail-send.component.css'],
  templateUrl: './mail-send.component.html'
})
export class MailSendComponent implements OnInit{
  personal: Personal;

  constructor(@Inject(PersonalService) private personalService: PersonalService) { }

  ngOnInit(): void {
    this.personal = this.personalService.getPersonal();
  }
}
