<div id="overall">
  <header>
    <div class="col-12" id="top">
      <div class="freecall"><strong>kostenlose Beratung</strong> 03834 - 83 43 0</div>
    </div>
    <div class="col-12" id="header">
      <a href="https://carport.de/" title="Startseite | CARPORTS|24">
        <img src="/assets/images/logo.gif" title="CARPORTS|24 - Ihr individuelles Carport vom Fachmann" alt="CARPORTS|24 - Ihr individuelles Carport vom Fachmann" height="50" width="215">
      </a>
    </div>
    <div class="col-12" id="teaser">
      <nav class="navbar navbar-expand-md navbar-dark">
        <div class="navbar-brand">
          <a href="https://carport.de/" title="Startseite | CARPORT|24">
            <button type="submit" class="mainback">
              <div class="mainback-back">zurück</div>
              Zur Hauptseite
            </button>
          </a>
          <!--<a href="http://www.carport.de" class="navbar-brand" title="zurück zur Hauptseite">
            <img src="/assets/images/button.back.home.png" title="zurück zur Hauptseite" alt="zurück zur Hauptseite">
          </a>-->
          <!--<form action="https://carport.de">
            <button type="submit" class="mainback">
              <div class="mainback-back">zurück</div>
              Zur Hauptseite
            </button>
          </form>-->
        </div>
        <button type="button" class="navbar-toggler navbar-toggler-right" data-toggle="collapse" data-target="#kalkulationSteps"  aria-controls="kalkulationSteps" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="kalkulationSteps">
          <ul class="navbar-nav mr-auto d-none d-sm-flex">
            <li *ngFor="let value of allCategories" class="nav-item">
              <!--<a class="link" [ngClass]="createLinkClass(value)" (click)="goToPage(value)">&nbsp;</a>-->
              <a class="link" [ngClass]="createLinkClass(value)" (click)="goToPage(value)">{{createLinkValue(value)}}</a>
            </li>
          </ul>
          <ul class="navbar-nav mr-auto d-sm-none">
            <li *ngFor="let value of allDoneCategories()" (click)="goToPage(value)" class="col-xs-12">
              <a class="type-link">
                <strong>{{value}}:</strong><br>
                <div *ngIf="chosenValues[value].anzahl">{{chosenValues[value].anzahl}}x {{chosenValues[value].title}}</div>
                <div *ngIf="!chosenValues[value].anzahl">{{chosenValues[value].title}}</div>
              </a>
              <a *ngIf="chosenValues[value].hasOwnProperty('subAbfrage')" class="type-link">
                <div>{{chosenValues[value].subAbfrage.title}}</div>
              </a>
            </li>
            <li class="col-12">
              <!--<a class="del-link" (click)="deleteInputs()" title="Auswahl löschen" data-toggle="collapse" data-target="#kalkulationSteps">
                <img src="/assets/images/calculator/button.delete.png" title="Auswahl löschen" alt="Auswahl löschen">
              </a>-->
              <button type="button" class="deleteInputs" (click)="deleteInputs()">Auswahl Löschen</button>
            </li>
          </ul>
        </div>
        <br style="clear: left;">
      </nav>
      <h2 *ngIf="currCategory === 'Bauart'" class="bottom">Konfigurieren Sie in 3 Minuten Ihren Carport!</h2>
      <div *ngIf="currCategory !== 'Bauart'" class="bottom">&nbsp;</div>
    </div>
  </header>

  <div id="container" class="container">
    <div class="row">

      <div class="d-none d-sm-none d-md-block col-4" id="column-2">
        <ul class="list" *ngIf="!isEmptyObject(chosenValues)">
          <li *ngFor="let value of allDoneCategories()" (click)="goToPage(value)">
            <a class="type-link">
              <strong>{{value}}:</strong><br>
              <div *ngIf="chosenValues[value].anzahl">{{chosenValues[value].anzahl}}x {{chosenValues[value].title}}</div>
              <div *ngIf="!chosenValues[value].anzahl">{{chosenValues[value].title}}</div>
            </a>
            <a *ngIf="chosenValues[value].hasOwnProperty('subAbfrage')" class="type-link">
              <div>{{chosenValues[value].subAbfrage.title}}</div>
            </a>
          </li>
        </ul>
        <button *ngIf="!isEmptyObject(chosenValues)" type="button" class="deleteInputs" (click)="deleteInputs()">Auswahl Löschen</button>
        <!--<a *ngIf="!isEmptyObject(chosenValues)" (click)="deleteInputs()" title="Auswahl löschen">
          <img class="pull-right" src="/assets/images/calculator/button.delete.png" title="Auswahl löschen" alt="Auswahl löschen">
        </a>-->
        <ul class="list" *ngIf="isEmptyObject(chosenValues)">
          <li class="center">
            <strong>Vielen Dank für Ihr Interesse unseren Caports.</strong>
            <br><br>
            In etwa <strong>3 Minuten </strong>wissen Sie, was Ihr Wunschcarport kostet!
            <br><br>
            Stellen Sie sich <strong>unverbindlich</strong> und bequem Schritt für Schritt Ihren individuellen Carport zusammen.
            <br><br>
            Sie sparen uns viel Zeit und Arbeit. Deshalb erhalten Sie einen <strong>10%-Online-Rabatt</strong> auf Ihren selbst kalkulierten Carport.
            <br><br>
            Wenn Sie es wünschen, senden wir Ihnen im Anschluss sofort ein unverbindliches Angebot für Ihren Carport als PDF per E-Mail zu.
            <br><br>
            Vielen Dank!
            <br><br>
          </li>
        </ul>
      </div>
      <div class="col-8">
        <h1>{{mapCategoriesToTitle()}}</h1>
        <app-auswahl (selectRequest)="chooseValue($event)" [allItems]="currAbfrage" [preSelectedValue]="chosenValues[currCategory]" *ngIf="showNextPage && currCategory !== 'finish'"></app-auswahl>
        <app-price (saveLoggingID)="saveLogID($event)" [allChosenValues]="chosenValues" *ngIf="currCategory === 'finish' && currPersonalPage === ''"></app-price>
        <app-personal-plz (requestNextPage)="nextPage()" (requestDownloadPDF)="downloadPDF()" *ngIf="currCategory === 'finish' && currPersonalPage === 'zip'"></app-personal-plz>
        <app-personal (requestSendMail)="sendMail()" *ngIf="currCategory === 'finish' && currPersonalPage === 'personal'"></app-personal>
        <app-mail-send *ngIf="currCategory === 'finish' && currPersonalPage === 'mail-send'"></app-mail-send>
        <div class="col-md-12 button">
          <button type="button" class="submit float-right" *ngIf="currPersonalPage === ''" (click)="nextPage()">Weiter</button>
          <button type="button" class="submit pdf-download float-right" style="width:230px; font-size:1.1em;" *ngIf="currCategory === 'finish' && currPersonalPage === 'zip'" (click)="personalPlzCompnChild.onSubmit()">Weitere Wünsche angeben</button>
          <button type="button" class="submit pdf-download float-right" *ngIf="currCategory === 'finish' && currPersonalPage === 'zip'" (click)="personalPlzCompnChild.onDownloadPDF()">Download PDF</button>
          <button type="button" class="submit pdf-download float-right" style="width:230px; font-size:1.0em;" *ngIf="currCategory === 'finish' && currPersonalPage === 'personal'" (click)="personalCompnChild.onSubmit()">Unverbindliche Anfrage senden</button>
          <!--<input class="submit float-right" type="image" *ngIf="currPersonalPage === ''" src="/assets/images/calculator/button.next.png" title="weiter zum nächsten Auswahlschritt" alt="weiter zum nächsten Auswahlschritt" (click)="nextPage()">-->
          <!--<input class="submit float-right" type="image" *ngIf="currCategory === 'finish' && currPersonalPage === ''" src="/assets/images/calculator/button.download-pdf.png" title="Download eines PDF-Dokuments" alt="Download eines PDF-Dokuments" (click)="downloadPDF()">-->
        </div>
      </div>
    </div>
  </div>
</div>
